import request from '@/router/axios'

export function getLoginAddress(query) {
    return request({
        url: '/steam/login',
        method: 'get',
        params: query
    })
}


export function loginAccount(query) {
    return request({
        url: '/steam/loginAccount',
        method: 'get',
        params: query
    })
}
